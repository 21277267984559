#UiLabelTooltip {
	top: -4px;
}

#UiLabelTooltip.tooltip {
	z-index: 9999999999;
	margin: 0;
	font-family: var(--sk-font-urbanist-family);
	font-style: normal;
	font-size: 12px;
	font-weight: 400;
	text-align: center;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
}

#UiLabelTooltip.tooltip.ChampionMultiSelectTooltip {
	z-index: 999999;

}

#UiLabelTooltip.tooltip:before {
	transform: rotate(45deg);
	content: "";
	border: 6px solid var(--sk-heading-color);
	position: absolute;
	width: 0px;
	height: 0px;
	left: auto;
	right: 39px;
	z-index: 1;
	bottom: 2px;
	top: auto;
	right: 43%;
	background-color: var(--sk-heading-color);
	display: flex;

}

#UiLabelTooltip.custom_menu_option_info_wrap_tooltip.tooltip:before {
    left: auto;
    bottom: 0px;
    top: 42%;
    right: 4px;
}

#UiLabelTooltip.tooltip.playbook_template_form_label_info_tooltip:before {
	right: 47%;
}

#UiLabelTooltip .tooltip-arrow {
	display: none;
}

#UiLabelTooltip.tooltip.bs-tooltip-right:before {
	transform: rotate(137deg);
	z-index: 1070;
	top: 45% !important;
	left: 0px;
	right: auto;
	margin: 0;
	font-family: var(--sk-font-urbanist-family);
	font-style: normal;
	font-size: 13px;
	font-weight: 400;
	/* line-height: 1.5; */
	text-align: center;
	/* text-align: start; */
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
}

#UiLabelTooltip.tooltip.bs-tooltip-right .arrow {
	display: none;
}


#UiLabelTooltip.bs-tooltip-left:before {
	transform: rotate(-45deg);
	content: "";
	border: 6px solid white;
	position: absolute;
	width: 0px;
	height: 0px;
	top: 44%;
	left: 94%;
	z-index: -1;
	bottom: -6px;
	/* box-shadow: 1px 1px 1px #ccc; */
	/*   box-shadow: 5px 6px 5px 0px rgba(0, 0, 0, 0.1); */
}

#UiLabelTooltip .tooltip.show {
	opacity: 1 !important;
}

#UiLabelTooltip.tooltip.bs-tooltip-top .arrow {
	width: 5px;
	height: 5px;
	left: 50% !important;
	bottom: 2px;
	right: auto;
	display: none;

}


#UiLabelTooltip.tooltip.bs-tooltip-top .arrow::before,
#UiLabelTooltip .bs-tooltip-auto[x-placement^="top"] .arrow::before {
	border-width: 7px 6px 0;
	border-top-color: #fff !important;
	left: auto;
}

#UiLabelTooltip.tooltip.bs-tooltip-top .arrow::before {
	border-right-color: transparent !important;
	border-top-color: #fff !important;
}

#UiLabelTooltip.bs-tooltip-right {
	left: 6px !important;
}

#UiLabelTooltip.bs-tooltip-right.hsInfo1 {
	left: 45px !important;
	top: 0px !important;
}

#UiLabelTooltip.bs-tooltip-right.hsInfo2 {
	left: 45px !important;
	top: 10px !important;
}


#UiLabelTooltip.bs-tooltip-right .arrow::before {
	border-width: 6px 10px 6px 0px;
	border-right-color: white !important;
	border-top-color: transparent !important;
}

#UiLabelTooltip .bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
	padding: 0 5px;
}

#UiLabelTooltip .bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
	width: 5px;
	height: 5px;
}

#UiLabelTooltip.bs-tooltip-right.arrow::before,
#UiLabelTooltip .bs-tooltip-auto[x-placement^="right"] .arrow::before {
	border-width: 2.5px 5px 2.5px 0;
	border-right-color: #fff;
}

#UiLabelTooltip .bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
	padding: 5px 0;
}

#UiLabelTooltip .bs-tooltip-bottom .arrow::before,
#UiLabelTooltip .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
	border-width: 0 2.5px 5px;
	border-bottom-color: #fff;
}

#UiLabelTooltip .bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
	padding: 0 5px;
}

#UiLabelTooltip .bs-tooltip-left .arrow,
#UiLabelTooltip .bs-tooltip-auto[x-placement^="left"] .arrow {
	right: 0;
	width: .4rem;
	height: .8rem;
}

#UiLabelTooltip.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
	left: 0;
	border-width: .6rem 0 .5rem .6rem;
	border-top-color: transparent !important;
	border-left-color: #fff;
}

#UiLabelTooltip.bs-tooltip-left .tooltip-inner {
	box-shadow: -2px 0px 4px 1px rgba(0, 0, 0, 0.1), 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
}


#UiLabelTooltip.bs-tooltip-left.actionCell .arrow {
	margin-top: 17px !important;
	top: auto !important;
}

#UiLabelTooltip.actionCell {
	margin-top: -18px !important;
}


#UiLabelTooltip .tooltip-inner {
	opacity: 1 !important;
	color: #ffff;
	/* min-width: 140px; */
	text-align: left;
	right: auto !important;
	margin: 0;
	background-color: #fff;
	padding: 5px 10px;
	font-weight: 400;
	box-shadow: -2px 0px 4px 1px rgba(0, 0, 0, 0.1), 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
	border-radius: 2px;
	font-size: 13px;
	;
	min-height: 30px;
	background-color: var(--sk-heading-color);
	border-radius: 6px;

}

#UiLabelTooltip.bs-tooltip-right .tooltip-inner {
	box-shadow: 0 1px 17px rgba(90, 97, 105, 0.1), 0 2px 12px rgba(90, 97, 105, 0.2);
}

.touchpointform_footer {
	padding: 10px 24px;
}

.uiBtn_wrap .modal-footer {
	border-top: none;
}

#UiLabelTooltip.tooltip[data-popper-placement^=right]:before {
	transform: rotate(45deg);
	content: "";
	border: 6px solid var(--sk-heading-color);
	position: absolute;
	width: 0px;
	height: 0px;
	left: 1px;
	/* right: 39px; */
	z-index: 1;
	bottom: 2px;
	top: 42%;
	/* right: 0; */
	background-color: var(--sk-heading-color);
}

#UiLabelTooltip.smart__contact__popup-info-message {
	margin-left: -8px;
}

#UiLabelTooltip.smart__assignment__rule__popup-info-message {
	margin-left: -8px;
}

#UiLabelTooltip.tooltip.smart__collaborator__tooltip {
	z-index: 99999999999;
}

.playbook__analytics__info__wrapper-message#UiLabelTooltip.tooltip:before {
	right: 47% !important;
}

.playbook__analytics__info__wrapper-message#UiLabelTooltip .tooltip-inner {
	text-align: left !important;
}

#UiLabelTooltip.tooltip.smart_modal_info_icon-message {
	z-index: 99999999999 !important;
	margin-left: -9px;
}

.history__log__tooltip#UiLabelTooltip.tooltip[data-popper-placement^=right]:before {
	top: 47% !important;
}


.pc-usage-trend-infi-tooltip.account__360__pu__score__tooltip#UiLabelTooltip.tooltip:before {
	left: 3px !important;
	top: 37% !important;
}

.pc-usage-trend-infi-tooltip.account__360__pu__score__tooltip.task_notification_on_info-icon#UiLabelTooltip.tooltip:before {
	left: 47% !important;
	top: 0% !important;
}

#UiLabelTooltip.tooltip.pc-usage-trend-infi-tooltip.account__360__pu__score__tooltip {
	top: -1px !important;
}

.manage__keyword__header__tooltip#UiLabelTooltip.tooltip:before {
	right: 46% !important;
}

.manage__keyword__table__tooltip#UiLabelTooltip.tooltip:before {
	right: 49% !important;
}

/* .manage__keywords-column-header-tooltip#UiLabelTooltip.tooltip:before {
	right: 31% !important;
} */

/* .manage__keywords-column-header-tooltip-amber#UiLabelTooltip.tooltip:before {
	right: 26% !important;
} */

.manage__keywords-column-header-tooltip-amber#UiLabelTooltip.tooltip:before {
	right: 26% !important;
}


.manage__keywords-column-header-tooltip-amber#UiLabelTooltip .tooltip-inner {
	text-align: left !important;

}

.manage__keywords-column-header-tooltip-red#UiLabelTooltip.tooltip:before {
	right: 47% !important;
}


.manage__keywords-column-header-tooltip-red#UiLabelTooltip .tooltip-inner {
	text-align: left !important;
}

.account__360__header__icon-tooltip#UiLabelTooltip.tooltip:before {
	bottom: 4px !important;
}

.grid_title__tooltip .tooltip-inner {
	text-align: left !important;
}

#UiLabelTooltip.tooltip.contact__add__new__user-icon-info-tooltip {
	top: 0px !important;
}


#UiLabelTooltip.tooltip a {
	color: #fab13e !important;
}

.sk__side__popup__header__profile-icon-tooltip#UiLabelTooltip.tooltip:before {
	top: 5% !important;
	left: 63% !important;
}

.getting__started__info__tooltip#UiLabelTooltip {
	top: 4px !important;
	left: 5px !important;
}

.getting__started__info__tooltip#UiLabelTooltip.tooltip:before {
	left: -5px !important;
}

.sidebar__pro__info__tooltip#UiLabelTooltip {
	top: 6px !important;
	left: 0px !important;
}



.sidebar__pro__info__disabled__tooltip#UiLabelTooltip {
	top: 8px !important;
	left: 5px !important;
}

.feature__usage__graph__tooltip#UiLabelTooltip {
	top: 13px !important;
	left: 3px !important;
}

.sidebar__pro__info__tooltip .tooltip-inner{
max-width: 300px !important;
width:300px !important
}

.sidebar__pro__info__disabled__tooltip .tooltip-inner{
	max-width: 300px !important;
	width:300px !important
	}

	.account__listing__link__tooltip.HWSelectMultiSelectTooltip#UiLabelTooltip.tooltip{
		left:-85px !important
	}